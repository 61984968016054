import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,ControlAccess,BtnItemBlockInfo} from "@gull";
import swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser,faEdit } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";

const ItemBlocScanWms = (props) => {
    const t =useTranslate()
    const [recuValue, setRecuValue]=useState(props.qteToScan)
    const [validButton,setValidButton]=useState(false)
    const [manualChange,setManualChange]=useState(false)

    const [showModalRestriction,setShowModalRestriction]=useState(false)
    
    const updateRecuValue=(e,selectedItem)=>{
        //fonction pour la modification manuelle qui s'exécute au onBlur et au clic sur Enter
        if(!e.key || e.key === 'Enter' ){
            if(recuValue !== '' && recuValue !== NaN && recuValue !== null){
                if((recuValue >= 0) && (+recuValue <= props.baseQte)){
                    let currentItem = props.itemsList['article'+selectedItem]
                    currentItem.qte = +recuValue
                    //on envoie la nouvelle information
                    props.setItemsList({...props.itemsList,['article'+selectedItem]:currentItem})

                    if(recuValue > 0){
                        props.setCurrentItemScan(selectedItem)
                    }
                }
            }
            setManualChange(false)
            props.setFocusManual(false)
        }
    }

    const handleChangeQte = (e) => {
        setRecuValue(e.target.value)
    }

    const handleDbleClick = () =>{        
        if(!props.restrictionManualChange.go){
            setShowModalRestriction(true)
            props.setManualFocusRestriction(true)
        }else{
            setRecuValue(props.qteToScan)
            setManualChange(true)
            props.setFocusManual(true)
        }
    }

    const handleBlur = (e) =>{
        updateRecuValue(e,props.idArticle)
    }

    const keyUp = (e) =>{
        updateRecuValue(e,props.idArticle)
    }

    let resetQteRecu = (articleToReset) => {
        // ici on récupère l'article qui doit être reset via son id 
        let toReset = props.itemsList['article'+articleToReset]
        //on récupère la quantité reçue pour la remettre à 0
        toReset.qte = 0
        //on met à jour les blocs affiché 
        props.setItemsList({...props.itemsList,['article'+articleToReset]:toReset})
        props.setCurrentItemScan(false)
        props.setError(false)
    }

    const closeModal = () =>{//Quand on ferme la modal sans avoir scanné de badge si restriction 
        setShowModalRestriction(false)
        setManualChange(false)
        props.setFocusManual(false)
    }

    const callback = () =>{
        props.setManualFocusRestriction(false)
        setShowModalRestriction(false)
        setRecuValue(props.qteToScan)
        setManualChange(true)
        props.setRestrictionManualChange({...props.restrictionManualChange,go:false})
        props.setFocusManual(true)
    }

    const handleClickSubmitBlock=()=>{
        // props.setConfirmValideAll(true)
        swal
        .fire({
            title: t('confirmValidateLine')+" ?",
            icon: "warning",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('btnYes'),
            cancelButtonText: t('btnNo'),
        })
        .then((result) => {
            if (result.value) {
                // props.validateOneBlock(props.idArticle,props.qteToScan,setValidButton)
                props.validateBlockBefore('one',props.idArticle,props.qteToScan,setValidButton)
            }
            // props.setConfirmValideAll(false)
        });
    }

    return(
        <div className="col-11">
            <Modal show={showModalRestriction} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'80px'}}>
                        <ControlAccess section={props.section} focus={true} restriction={props.restrictionManualChange} setRestriction={props.setRestrictionManualChange} moduleId={props.wmsid} callback={callback}/>
                    </div>
                </Modal.Body>
            </Modal>
            <CardPerso 
                header={props.cardTitle} 
                saClass='fs-card-scan' 
                bgBody='true' 
                bgBodyColor={props.bgColorCondition} 
            >
                <div style={{marginBottom:'0.75em'}} className="fs-6 d-flex justify-content-center">
                    {props.codeUpc}&nbsp;&nbsp;<BtnItemBlockInfo setHideInput={props.setSwalAffiche} articleId={props.idArticle} wmsid={props.wmsid}/>
                </div>
                {(props.codeUpc2) && 
                    <div style={{marginBottom:'0.75em'}} className="fs-6 text-center">
                        {props.codeUpc2}
                    </div>
                }
                <div className="row border-top border-dark pt-1">
                    <div className="col-6 text-center">
                        <label style={{marginBottom:'0.75em'}}>{props.titleQteInit}</label>
                        <div className="fs-6">{props.baseQte}</div>
                    </div>
                    <div className="col-6 text-center">
                        <label style={{marginBottom:'0.75em'}}>{props.titleQteScan}</label>
                        {(manualChange) ? 
                            <>
                                <input id={props.idArticle} ref={props.inputManual} className=" col-8 text-center fs-6" type="number" value={recuValue} onChange={(e)=>handleChangeQte(e)} onKeyUp={(e)=>keyUp(e)} onBlur={(e)=>handleBlur(e)}/> <br />
                            </>
                        :
                            <div className="fs-6">
                                <FontAwesomeIcon className='text-primary fs-5 pointer' icon={faEdit} onClick={()=>handleDbleClick()}/>&nbsp;
                                {props.qteToScan}
                            </div>
                        }
                        <span className="fs-6">
                            <FontAwesomeIcon 
                                icon={faEraser} 
                                onClick={()=>resetQteRecu(props.idArticle)}
                            />
                        </span>
                    </div>
                    {(props.qteToScan > 0) &&
                        <button 
                            disabled={validButton || props.validButtonScreen} 
                            className="btn btn-primary mt-3"
                            onClick={()=>handleClickSubmitBlock()}
                        >
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    }
                </div>
            </CardPerso>
        </div>
    )
}

export default ItemBlocScanWms;