import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual';
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";
import axios from 'axios'

const  ExportComptabilite = (props) => {
    const t = useTranslate();
    const [exportCsvLoading,setExportCsvLoading] = useState(false)
    const goExportCsv = async (header) => {
        setExportCsvLoading(true)
        let obj = {}
        const response = await axios.post(process.env.REACT_APP_API_URL+props.url,obj
            ).then((response) => {
                setExportCsvLoading(false)
                if(props.setReload){ 
                    props.setReload(Date.now())
                }
                return(
                    swal.fire({
                        icon: 'success',
                        title: t('csvWillBeReady')
                    })
                ) 
            }).catch((error) => {
                if(error=='end_session') return;
                setExportCsvLoading(false)
                return(
                    swal.fire({
                        icon: 'error',
                        title: t('unknowError')
                    })
                ) 
            })
    }

    return (<>
        {(props.type=='button') ?
            <button disabled={props.disabled ? props.disabled : exportCsvLoading} className={`btn btn-dark btn-sm ${(props.noMarginTop) ? '' : 'mb-2'}`} onClick={() => goExportCsv()}>
                {exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : props.title}
            </button>
            :
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {props.title}
                    </Tooltip>
                }
            >
                <span className="text-success" style={{cursor:'Pointer'}} onClick={() => goExportCsv()}>{exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <FontAwesomeIcon icon={faFileCsv} />}</span>
            </OverlayTrigger> 
        }
    </>)
}

export default ExportComptabilite;