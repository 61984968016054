import { CategoryInventoryScan } from "@gull";
import { lazy, useContext } from "react";

const Erp = lazy(() => import("./Erp"));
const Articles = lazy(() => import("./Articles/Articles/Welcome"));
const Fournisseurs = lazy(()=> import("./Articles/Fournisseurs/Fournisseurs"));
const StockItem = lazy(()=> import("./Articles/Stock/StockItem"));
const FournisseursDetailsTab = lazy(()=> import("./Articles/Fournisseurs/FournisseursDetailsTab"));
const FamilleArticle = lazy(() => import("./Articles/FamilleArticle/FamilleArticle"));
const Marques = lazy(() => import("./Articles/Marques/Marques"));
const ArticleDetail = lazy(() => import("./Articles/ArticleDetail/ArticleDetail"));
const Promotion = lazy(() => import("./Articles/Promotion/Promotion"));
const PromotionDetail = lazy(() => import("./Articles/Promotion/PromotionDetail"));
const ListeArticleTable = lazy(() => import("./Articles/ListeArticle/ListeArticleTable"));
const ListeArticleDetailErp = lazy(() => import("./Articles/ListeArticle/ListeArticleDetailErp"));
const BonCommande = lazy(() => import("./BonCommande/BonCommande"));
const BonCommandeDetail = lazy(() => import("./BonCommande/BonCommandeDetail"));
const Shipment = lazy(() => import("./Shipment/Shipment"));
const ShipmentDetail = lazy(() => import("./Shipment/ShipmentDetail"));
const ReceiptVoucher = lazy(() => import("./Shipment/ReceiptVoucher"));
const AutoRestockMenu = lazy(() => import("./Reassort/AutoRestockMenu"));
const AutoReassortParent = lazy(() => import("./Reassort/AutoReassortParent"));
const InternalRestockException = lazy(() => import("./ReassortException/InternalRestockException"));
const TransferOrder = lazy(() => import("./TransferOrder/TransferOrder"));
const ToDetail = lazy(() => import("./TransferOrder/ToDetail"));
const Session = lazy(() => import("./Session/Session"));
const TicketCommandesClientDevis = lazy(() => import("./TicketCommandesClientDevis/TicketCommandesClientDevis"));
const DetailTicketAdmin = lazy(() => import("@gull/components/DetailTicketAdmin/DetailTicketAdmin"));
const DetailCommandeAdmin = lazy(() => import("@gull/components/DetailCommandeAdmin/DetailCommandeAdmin"));
const DetailDevisAdmin = lazy(() => import("@gull/components/DetailDevisAdmin/DetailDevisAdmin"));
const Inventory = lazy(() => import("./Inventaire/Inventory"));
const InventoryReport = lazy(() => import("./Inventaire/InventoryReport"));
const CategoryInventory = lazy(() => import("./Inventaire/Comp/CategoryInventory"));
const CategoryInventoryAdminScanMasse = lazy(() => import("./Inventaire/Comp/CategoryInventoryAdminScanMasse"));
const ZoneInventory = lazy(() => import("./Inventaire/Comp/ZoneInventory"));
const ZoneInventoryAdminScan = lazy(() => import("./Inventaire/Comp/ZoneInventoryAdminScan"));
const BinsInventory = lazy(() => import("./Inventaire/Comp/BinsInventory"));
const BinsInventoryAdminScan = lazy(() => import("./Inventaire/Comp/BinsInventoryAdminScan"));
const ObjectifPos = lazy(() => import("./ObjectifPos/ObjectifPos"));
const TurnoverPos = lazy(() => import("./CA/TurnoverPos"));
const TemplateBilling = lazy(() => import("./Facture/TemplateBilling"));
const TemplateEmail = lazy(() => import("./Facture/TemplateEmail"));
const NotificationListErp = lazy(() => import("./Notification/NotificationListErp"));
const NotificationDetailErp = lazy(() => import("./Notification/NotificationDetailErp"));
const MatchInternalId = lazy(() => import("./MatchInternalId/MatchInternalId"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));
const GiftCardListErp = lazy(() => import("./GiftCard/GiftCardListErp"));
const HistoriqueStockAdjustment = lazy(() => import("./HistoriqueMenu/HistoStockAsjustment/HistoriqueStockAdjustment"));
const RemiseManuelle = lazy(() => import("./HistoriqueMenu/RemiseManuelle/RemiseManuelle"));
const CancellationReceipt = lazy(() => import("./HistoriqueMenu/CancellationReceipt/CancellationReceipt"));
const HistoriqueAttemptedStockAdjustment = lazy (()=> import("./HistoriqueMenu/HistoriqueAttemptedStockAdjustment"))
const FideliteWelcome = lazy (()=> import("./Fidelite/FideliteWelcome"))
const PlanFideliteDetail = lazy (()=> import("./Fidelite/PlanFideliteDetail/PlanFideliteDetail.jsx"))
const RecompenseFideliteConfig = lazy (()=> import("./Fidelite/RecompenseFideliteConfig/RecompenseFideliteConfig"))
const FideliteListErp = lazy(() => import("./Fidelite/FideliteListErp/FideliteListErp"));
const CashOutGroupeErp = lazy (()=> import("./CashOutGroupeErp/CashOutGroupeErp"))
const ClientWelcome = lazy (()=> import("./Client/ClientWelcome"))
const ClientProfilErp = lazy (()=> import("./Client/ClientDetail/ClientProfilErp"))
const ErpReport = lazy (()=> import("./ReportPbi/ErpReport"))
const PeripheriqueErp = lazy (()=> import("./PeripheriqueErp/PeripheriqueErp"))
const ComptabiliteMain = lazy (()=> import("./Comptabilite/ComptabiliteMain"))

const erpRoutes = [
    {
      path: "/erp/welcome",
      component: Articles
    },
    {
        path: "/erp/item",
        component: Articles
    },
    {
        path: "/erp/stock/item",
        component: StockItem
    },
    {
        path: "/erp/accounting",
        component: ComptabiliteMain
    },
    {
        path: "/erp/itemfamily",
        component: FamilleArticle
    },
    {
        exact:true,
        path: "/erp/brand",
        component: Marques
    },
    {
        exact:true,
        path: "/erp/provider",
        component: Fournisseurs
    },
    {
        path: "/erp/provider/details/:id",
        component: FournisseursDetailsTab
    },
    {
        path: "/erp/article/edit/:id",
        component: ArticleDetail
    },
    {
        exact:true,
        path: "/erp/purchase-order",
        component: BonCommande
    },
    {
        path: "/erp/purchase-order/details/:id",
        component: BonCommandeDetail
    },
    {
        exact:true,
        path: "/erp/shipment",
        component: Shipment
    },
    {
        path: "/erp/shipment/details/:id",
        component: ShipmentDetail
    },
    {
        path: "/erp/shipment/receipt-voucher/:shipmentid/:cartonnumber",
        component: ReceiptVoucher
    },
    {
        exact:true,
        path: "/erp/automatic-restock/parent-item/:id",
        component: AutoReassortParent
    },
    {
        exact:true,
        path: "/erp/automatic-restock/:id?/:cur_page?",
        component: AutoRestockMenu
    },
    {
        exact:true,
        path: "/erp/internal-restock-exception",
        component: InternalRestockException
    },
    {
        exact:true,
        path: "/erp/transfer-order",
        component: TransferOrder
    },
    {
        path: "/erp/transfer-order/details/:id",
        component: ToDetail
    },
    {
        exact:true,
        path: "/erp/promotion",
        component: Promotion
    },
    {
        path: "/erp/promotion/detail/:id",
        component: PromotionDetail
    },
    {
        path: "/erp/items-list",
        component: ListeArticleTable
    },
    {
        path: "/erp/items-list-detail/:id",
        component: ListeArticleDetailErp
    },
    {
        path: "/erp/caisse/session",
        component: Session
    },
    {
        exact:true,
        path: "/erp/caisse/ticket",
        component: TicketCommandesClientDevis
    },
    {
        exact:true,
        path: "/erp/caisse/ticket/:ticketId",
        component: DetailTicketAdmin
    },
    {
        exact:true,
        path: "/erp/caisse/sales-order/:saleOrderId",
        component: DetailCommandeAdmin
    },
    {
        exact:true,
        path: "/erp/caisse/devis/:devisId",
        component: DetailDevisAdmin
    },
    {
        exact:true,
        path: "/erp/caisse/giftcard",
        component: GiftCardListErp
    },
    {
        exact:true,
        path: "/erp/caisse/fidelite",
        component: FideliteListErp
    },
    {
        exact:true,
        path: "/erp/caisse/cashout",
        component: CashOutGroupeErp
    },
    {
        exact:true,
        path: "/erp/inventory",
        component: Inventory
    },
    // {
    //     exact:true,
    //     path: "/erp/inventory/import",
    //     component: InventoryImport
    // },
    {
        exact:true,
        path: "/erp/inventory/report/:id",
        component: InventoryReport
    },
    {
        exact:true,
        path: "/erp/inventory/categorie/:id/:zoneid",
        component: CategoryInventory
    },
    {
        exact:true,
        path: "/erp/inventory/categorie/:id/:zoneid/:rhid",
        component: CategoryInventoryAdminScanMasse
    },
    {
        exact:true,
        path: "/erp/inventory/zone/:id/:zoneid",
        component: ZoneInventory
    },
    {
        exact:true,
        path: "/erp/inventory/zone/:id/:zoneid/:zoneinventoryid/:rhid",
        component: ZoneInventoryAdminScan
    },
    {
        exact:true,
        path: "/erp/inventory/bins/:id/:zoneid",
        component: BinsInventory
    },
    {
        exact:true,
        path: "/erp/inventory/bins/:id/:zoneid/:binsinventoryid/:binsid/:binsnum/:rhid",
        component: BinsInventoryAdminScan
    },
    {
        path: "/erp/objectifpos",
        component: ObjectifPos
    },
    {
        path: "/erp/turnoverpos",
        component: TurnoverPos
    },
    // {
    //     path: "/erp/billing/template",
    //     component: TemplateBilling
    // },
    // {
    //     path: "/erp/email/template",
    //     component: TemplateEmail
    // },
    {
        path: "/erp/notifications/list",
        component: NotificationListErp
    },
    {
        path: "/erp/notifications/detail/:id",
        component: NotificationDetailErp
    },
    {
        path: "/erp/internal-id-match",
        component: MatchInternalId
    },
    {
        path: "/erp/historique/edit/:id",
        component: HistoriqueDetail
    },
    {
        path: "/erp/historical/stock-adjustment",
        component: HistoriqueStockAdjustment
    },
    {
        path: "/erp/historical/manual-deposit",
        component: RemiseManuelle
    },
    {
        path: "/erp/historical/cancellation-on-receipt",
        component: CancellationReceipt
    },
    {
        path: "/erp/historical/stock-adjustment-attempt",
        component: HistoriqueAttemptedStockAdjustment
    },
    {
        exact:true,
        path: "/erp/loyalty-plan",
        component: FideliteWelcome
    },
    {
        exact:true,
        path: "/erp/loyalty-plan/detail/:id",
        component: PlanFideliteDetail
    },
    {
        exact:true,
        path: "/erp/loyalty-rewards-configuration",
        component: RecompenseFideliteConfig
    },
    {
        exact:true,
        path: "/erp/client",
        component: ClientWelcome
    },
    {
        exact:true,
        path: "/erp/client/detail/:id",
        component: ClientProfilErp
    },
    {
        exact:true,
        path: "/erp/report",
        component: ErpReport
    },
    {
        exact:true,
        path: "/erp/peripherals",
        component: PeripheriqueErp
    },
  ];
  
  export default erpRoutes; 