import React, {useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'

const CaisseCloseForm = (props) =>{
    const t = useTranslate()

    const onfocusAction = (inputId) =>{
        let element = document.getElementById(inputId)
        if(element){ 
            if(element==document.activeElement){
                element.select()
            }else{
                element.blur()
            }
        }
    }

    let suppId = props.idComplete ? props.idComplete : ''

    return(
        <>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    10 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("dixmille"+suppId)} style={{textAlign: "right"}} id={"dixmille"+suppId} type="number" min="0" className="form-control" value={props.detailCash.dixmille.nbr} onChange={(e) => props.calcSum(e,'dixmille')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdixmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.dixmille.nbr*props.detailCash.dixmille.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    5 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinqmille"+suppId)} style={{textAlign: "right"}} id={"cinqmille"+suppId} type="number" min="0" className="form-control" value={props.detailCash.cinqmille.nbr} onChange={(e) => props.calcSum(e,'cinqmille')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinqmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.cinqmille.nbr*props.detailCash.cinqmille.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    1 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("mille"+suppId)} style={{textAlign: "right"}} id={"mille"+suppId} type="number" min="0" className="form-control" value={props.detailCash.mille.nbr} onChange={(e) => props.calcSum(e,'mille')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.mille.nbr*props.detailCash.mille.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    500 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinqcent"+suppId)} style={{textAlign: "right"}} id={"cinqcent"+suppId} type="number" min="0" className="form-control" value={props.detailCash.cinqcent.nbr} onChange={(e) => props.calcSum(e,'cinqcent')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinqcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.cinqcent.nbr*props.detailCash.cinqcent.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    200 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("deuxcent"+suppId)} style={{textAlign: "right"}} id={"deuxcent"+suppId} type="number" min="0" className="form-control" value={props.detailCash.deuxcent.nbr} onChange={(e) => props.calcSum(e,'deuxcent')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdeuxcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.deuxcent.nbr*props.detailCash.deuxcent.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    100 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cent"+suppId)} style={{textAlign: "right"}} id={"cent"+suppId} type="number" min="0" className="form-control" value={props.detailCash.cent.nbr} onChange={(e) => props.calcSum(e,'cent')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.cent.nbr*props.detailCash.cent.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    50 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinquante"+suppId)} style={{textAlign: "right"}} id={"cinquante"+suppId} type="number" min="0" className="form-control" value={props.detailCash.cinquante.nbr} onChange={(e) => props.calcSum(e,'cinquante')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinquante" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.cinquante.nbr*props.detailCash.cinquante.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    20 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("vingt"+suppId)} style={{textAlign: "right"}} id={"vingt"+suppId} type="number" min="0" className="form-control" value={props.detailCash.vingt.nbr} onChange={(e) => props.calcSum(e,'vingt')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultvingt" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.vingt.nbr*props.detailCash.vingt.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    10 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("dix"+suppId)} style={{textAlign: "right"}} id={"dix"+suppId} type="number" min="0" className="form-control" value={props.detailCash.dix.nbr} onChange={(e) => props.calcSum(e,'dix')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdix" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.dix.nbr*props.detailCash.dix.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    5 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinq"+suppId)} style={{textAlign: "right"}} id={"cinq"+suppId} type="number" min="0" className="form-control" value={props.detailCash.cinq.nbr} onChange={(e) => props.calcSum(e,'cinq')} />
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinq" className="toSum">{new Intl.NumberFormat('fr-FR').format(props.detailCash.cinq.nbr*props.detailCash.cinq.multiple)}</span> XPF</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CaisseCloseForm;