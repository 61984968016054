import React, { useState,useRef } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {PosInfoPrice,InpDataList} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";

const useSearchItem = () => {
    const [loading,setLoading]=useState(false)
    const [listItems,setListItems]=useState([])
    const [datas,setDatas] = useState(listItems)
    const searchInput = useRef('')
    const t =useTranslate()

    const searchItemByName = async (inputValue) => {
        setLoading(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoading(false)
            setListItems(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoading(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loading,searchItemByName,listItems,datas,setDatas,searchInput]
}

const ApercuPrixArticleBarre = (props) =>{
    const t = useTranslate();
    const [loading,searchItemByName,listItems,datas,setDatas,searchInput]=useSearchItem()
    const [searchValueName,setSearchValueName]=useState(false)
    const [lastSearch,setLastSearch]=useState() 
    const [timer,setTimer] = useState(false)
    
    const dataListDrop = useRef('')

    const [showAlert,setShowAlert]=useState(false)
    const [priceDataAlert,setPriceDataAlert]=useState({})
    const [loadingAlert,setLoadingAlert]=useState(false) 

    const handleSearch = (e,declenchement) => {
        setSearchValueName(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDrop.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                searchItemByName(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDrop.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listItems.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleSubmit = async (e,articleid,inputName,reference) => {
        setShowAlert(true)
        setLoadingAlert(t('searchInWait'))
        setPriceDataAlert({})

        setSearchValueName('')
        dataListDrop.current.classList.add("d-none");

        let article = (e) ? e.target.value : articleid
        let urlArticle = (e) ? "api/erp/article/price_reference/" : "api/erp/article/price/"

        const response = await axios.get(process.env.REACT_APP_API_URL+urlArticle+article
        ).then((response) => {
            if(response.data.hasOwnProperty('valid') && !response.data.valid){
                setLoadingAlert(false)
                setShowAlert(false)
                swal.fire(t('articleNoReferenceFind'), "", "error");
            }else{   
                if(e){
                    setPriceDataAlert(response.data.data.article)  
                }else{
                    setPriceDataAlert(response.data.data)  
                }
                setLoadingAlert(false)
            }
        }).catch((error) => {
            setLoadingAlert(false)
            if(error=='end_session') return;
            setShowAlert(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const handleCLick = (id,label,inputName,reference) => {
        handleSubmit(false,id,inputName,reference)
    }

    const handleKeyPress = (e) =>{
        if(e.charCode==13){
            if(timer){
                clearTimeout(timer)
                setTimer(false)
            }
            handleSubmit(e,false,false,false)
        }
    }

    return (
        <div>
            <PosInfoPrice 
                showAlert={showAlert} 
                setShowAlert={setShowAlert}
                priceDataAlert={priceDataAlert}
                loadingAlert={loadingAlert}

                digit={props.digit}
                devise={props.devise}
                actualShop={props.actualShop}
            />
            <div className="form-group position-relative">
                <InpDataList
                    placeholder={t('consultPriceItem')+'...'}
                    value={searchValueName}
                    onClick={handleCLick}
                    onKeyPress={handleKeyPress}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListDrop}
                    inputRef={searchInput}
                    inputName="seePriceItem"
                    listPosition="top"
                />
                {(loading) &&
                    <span className='spinnerSearchStockPos'><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                }
            </div>
        </div>
    )
};

export default ApercuPrixArticleBarre;