import React, {lazy,Suspense} from 'react';
import {Loading} from '@gull'
import { useTranslate } from 'react-redux-multilingual'

const DefaultViewPay = lazy(() => import('./DefaultViewPay'));
const TabSquareViewPay = lazy(() => import('./TabSquareViewPay'));

const MainPaymentView = (props) => {
    const t = useTranslate();

    return(
        <div>
            {((props.section.advanceparams && !props.section.advanceparams.hasOwnProperty('modal_paiement')) || (props.section.advanceparams && props.section.advanceparams.hasOwnProperty('modal_paiement') && props.section.advanceparams.modal_paiement=='default')) ?
                <Suspense fallback={<Loading></Loading>}>
                    <DefaultViewPay {...props}/>
                </Suspense>
            :
            (props.section.advanceparams && props.section.advanceparams.hasOwnProperty('modal_paiement') && props.section.advanceparams.modal_paiement=='tab_square') ?
                <Suspense fallback={<Loading></Loading>}>
                    <TabSquareViewPay {...props}/>
                </Suspense>
            :
                false
            }
        </div>
    )
}

export default MainPaymentView;