import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,ControlAccess} from "@gull"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle,faPrint } from '@fortawesome/free-solid-svg-icons'
import { modePaiementDefaultAll,modePaiementFidelite } from 'app/modePaiementDefaultAll';
import { epson_tm_h6000V_Check} from 'app/imprimeCheck';
import swal from "sweetalert2";
import axios from 'axios'

const LinePaymentRecordRightForm = (props) =>{
    const t =useTranslate()
    const [loading,setLoading]=useState(false)
    const [showRestriction,setShowRestriction]=useState(false) 
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const handleDelete = async (paimentRecordId) =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.cookies.current_ticket,
            delete_paiement_record:paimentRecordId
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setMonnaieRendu(0)

                props.setCurrentTicket(response.data.data)
                let objFideliteInfo = {
                    data:response.data.fidelite_client.liste_fidelite_client,
                    mode_fidelite:response.data.fidelite_client.mode_fidelite,
                    total_point_fidelite_client:response.data.total_point_fidelite_client,
                    valeur_cfp:(response.data.fidelite_client.valeur_cfp) ? response.data.fidelite_client.valeur_cfp : null,
                }
                props.setClientFideliteInfo(objFideliteInfo)
                setLoading(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    const handleClick = (paymentId) =>{
        if(!props.restrictionRemoveLinePayment.go){
            setShowRestriction(paymentId)
        }else{
            handleDelete(paymentId)
        }
    }

    const callback = () =>{
        handleDelete(showRestriction) //showRestriction quand il est true est l'id du monde de paiement qu'on souhaite supprimer
        setShowRestriction(false)
        props.setRestrictionRemoveLinePayment({...props.restrictionRemoveLinePayment,go:false})
    }

    const actionHideControlAccess = () =>{
        setShowRestriction(false)
    }

    const printCheck = () =>{
        epson_tm_h6000V_Check(props.value.montant,props.currentTicket.created_at,props.peripheriqueParam,props.section)
    }
    
    return(
        <div className={props.style}>
            <div className="col-7">
                {(modePaiementDefaultAll.includes(props.value.mode)) ?
                    t(props.value.mode)
                :
                    (modePaiementFidelite.includes(props.value.mode)) ?
                        t('loyalty')
                    :
                        props.value.mode
                }
                {(props.peripheriqueParam.imprimante_cheque && props.value.mode=='cheque') ? <>&nbsp;&nbsp;<button className="btn btn-sm btn-primary" ><FontAwesomeIcon icon={faPrint} onClick={()=>printCheck()}/></button></> : false}
            </div>
            <div className="col-5 text-center">
                {<>
                    <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} /> 
                    {(loading) ? 
                        <div className="spinner-border spinner-border-sm crossPaymentPosition"></div> 
                    : 
                        (props.currentTicket.status==0 && props.value.pos_id==idsLocalStorage[0]) ?
                            <FontAwesomeIcon icon={faTimesCircle} className='crossPaymentPosition pointer' onClick={()=>handleClick(props.value.id)}/>
                        : 
                            false
                    }
                </>}
            </div>
            {(showRestriction) ?
                <div className="col-12 mt-2">
                    <ControlAccess section={props.section} noMarge={true} focus={true} btnHideControlAccess={true} actionHideControlAccess={actionHideControlAccess} restriction={props.restrictionRemoveLinePayment} moduleId={props.posid} setRestriction={props.setRestrictionRemoveLinePayment} callback={callback} />
                </div>
            :
                false
            }
        </div>
    )
}

export default LinePaymentRecordRightForm;