export const squarePaymentColors = [
  "#1F2937", /* Gris foncé */
  "#B91C1C", /* Rouge profond */
  "#2563EB", /* Bleu vif */
  "#F59E0B", /* Orange vif */
  "#0D9488", /* Turquoise foncé */
  "#6D28D9", /* Indigo foncé */
  "#111827", /* Noir charbon */
  "#9333EA", /* Violet vif */
  "#D97706", /* Orange foncé */
  "#1E40AF", /* Bleu marine foncé */
  "#10B981", /* Vert vif */
  "#4B5563", /* Gris anthracite */
  "#EF4444", /* Rouge vif */
  "#22D3EE", /* Cyan vif */
  "#5B21B6", /* Indigo profond */
  "#065F46", /* Vert sapin */
  "#374151", /* Bleu-gris */
  "#B45309", /* Ocre */
  "#1D4ED8", /* Bleu roi */
  "#0077CC", /* Bleu ciel foncé */
  "#DC2626", /* Rouge brique */
  "#0F172A", /* Bleu nuit */
  "#6B7280", /* Gris neutre */
  "#0284C7", /* Cyan profond */
  "#14B8A6", /* Turquoise vif */
  "#1E3A8A", /* Bleu cobalt */
  "#064E3B", /* Vert jungle */
  "#7C3AED", /* Violet profond */
  "#F97316", /* Orange intense */
  "#0E7490", /* Turquoise pétrole */
];

