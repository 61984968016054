import React,{useState,useRef} from "react";
import { InpDataList} from "@gull";
import { useTranslate } from 'react-redux-multilingual'

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

const ArticleBarSearch = (props) =>{
    const t =useTranslate()
    const dataListDrop = useRef('')
    const [timer,setTimer] = useState(false)
    const [lastSearch,setLastSearch]=useState(false)

    const resetSearchItem = () =>{
        props.setValueInput('')
        if(props.setItemId) props.setItemId('')
        if(props.setDisabled) props.setDisabled(false)
        if(props.setArticle) props.setArticle(false)
        if(props.setRelanceFocus) props.setRelanceFocus(Date.now())
        if(props.setValue){ 
            props.setValue(`${props.registerNameArticleId}[${props.index}].name`, '')
            props.setValue(`${props.registerNameArticleName}[${props.index}].name`, '')
        } //utilisé uniquement dans l'onglet expédition et livraison des e-commerces
    }

    const handleSearch = (e,declenchement,delay) => {
        props.setValueInput(e.target.value)

        if(e.target.value.length>=declenchement){
            dataListDrop.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                let tempTimer = setTimeout(() => {
                    if(e.target.value!=''){
                        props.loadItems(e.target.value.substring(0, declenchement))
                    }
                }, delay);
                
                setTimer(tempTimer)
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDrop.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = props.listSearchItem.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            props.setDatas(ajour)
        }
    }

    const handleCLick = (id,label,inputName,reference) => {
        props.setValueInput(label)
        props.handleSubmit(false,id,inputName,reference)
    }

    const handleBlur = () => {
        //ici props.noBlur est un boolean, dans certains cas comme dans le POS, apres avoir cliqué sur l'article choisit, on vide le champs de saisie.
        //sauf que pour les bons de commandes, shipment, on a besoin de garder les nom de l'article dans le champ pour les formulaires d'ajout d'article 
        if(!props.noBlur) props.setValueInput('') 
        dataListDrop.current.classList.add("d-none");
    }

    const handleFocus = () =>{
        if(props.noBlur && props.valueInput!=''){
            //ici si on a fait une recherche dans l'input, qu'on sort du champ et qu'on revient, on souhaite refaire apparaite la liste de recherche si il y en avait une mais uniquement si noBlur est true 
            dataListDrop.current.classList.remove("d-none");
        }
    }

    const handleKeyPress = (e) =>{
        if(e.charCode==13){
            if(timer){
                clearTimeout(timer)
                setTimer(false)
            }
            props.handleSubmit(e,false,false,false)
        }
    }

    return(
        <div className="col-12">
            <div className="form-group">
                <div className="row">
                    <div className={`${!props.noBtnErase ? "col-10" : "col-12"}`}>
                        <InpDataList
                            placeholder={t('article')+'...'}
                            value={props.valueInput}
                            onClick={handleCLick}
                            onChange={handleSearch}
                            datas={props.datas}
                            declenchement={3}
                            listRef={dataListDrop}
                            inputRef={props.searchInput}
                            onBlur={handleBlur}
                            inputName="scan"
                            delay="1000"
                            onKeyPress={handleKeyPress}
                            onFocus={handleFocus}
                            disabled={(props.disabled) ? props.disabled : false}
                            showImg={true}
                        />
                        {(props.loadingSearchItem) &&
                            <span className="spinnerRightPos"><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                        }
                    </div>
                    {!props.noBtnErase ?
                        <div className="col-2">
                            <button disabled={props.disabledDeleteBtn} className="btn btn-danger btn-sm" onClick={() =>resetSearchItem() }>{(props.icon && props.icon=='true') ? <FontAwesomeIcon icon={faEraser} /> : t('btnEffacer')}</button>
                        </div> 
                    :
                        false
                    }
                </div>
            </div>
            {(props.dataNextShortcut && props.dataNextShortcut.length!=0) ?
                <div className="row">
                    <div className="col">
                        <button className="btn btn-sm btn-warning mb-1" onClick={()=>props.resetSearchRaccourci()}>Reset</button>&nbsp;&nbsp;
                        <button className="btn btn-sm btn-dark mb-1" onClick={()=>props.getPreviousSearchRaccourci()}>{t('btnBack')}</button>
                    </div>
                </div>
                :
                    false
            }
        </div>
    )
}

export default ArticleBarSearch;

