import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from '@gull'

const PadFidelitePayment = (props) => {
    const t = useTranslate();

    const handleClick = (name) => {
        if(name=='cashback'){
            props.handleChange(false,'amount',props.amount)
        }else if(name=='recompense'){
            props.handleChange(false,'modepaiement',props.amount+','+props.articleId)
        }else{
            props.handleChange(false,'modepaiement',props.amount)
        }
    };

    return(
        <div className="col-4 position-relative" style={{padding:"1px"}}>
            <div className="col-12 bg-success rounded raccourciPos text-white" onClick={()=>handleClick(props.mode)}>
                <div className="textAlign p-2" style={{width:'100%', height:'100px'}}>
                    <div className="mb-1">{props.text}</div>
                    {<div>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.amount} />}</div>}
                </div>
            </div>
            {(props.btnDisabled || props.disabledPad || props.waitTpe) ?
                <div className="disabled rounded">
                </div>
            :
                false
            }
        </div>
    )
}

export default PadFidelitePayment;