import React, {useContext, useEffect, useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from '@gull'
import {modePaiementDefaultAll,modePaiementFidelite} from 'app/modePaiementDefaultAll'
import {squarePaymentColors} from 'app/squarePaymentColors'
import { IsAuthContext } from 'app/App';

const PadAddPayment = (props) => {
    const t = useTranslate();
    const {authParam} = useContext(IsAuthContext);
    const [otherPay,setOtherPay] = useState([])
    const [toPay,setToPay] = useState(0)
    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    useEffect(function () {
        if(props.amountVal==''){
            setToPay(props.valueDue)
        }else{
            if(props.isFor!='espece' || (props.isFor=='espece' && props.indexColor==1)){
                //Si on est pas sur le mode de paiement espece ou si on est sur le mode espece, on exécute que sur le premier bloc qui représente le total restant à payer
                //Les autres blocs sont des valeurs en dur 
                setToPay(props.amountVal)
            }else{
                setToPay(props.valueDue)
            }
        }
    }, [props.amountVal,props.valueDue])

    useEffect(function () {
        if(props.currentShop){
            let otherModePay = []
            props.currentShop.modepaiement.map(value => {
               if(!modePaiementDefaultAll.includes(value)) otherModePay.push(value)
            })
            setOtherPay(otherModePay)
        }
    }, [props.currentShop])

    let arrAllPayment = [...modePaiementDefaultAll,...modePaiementFidelite,...otherPay]
    let paymentIndex = (props.indexColor) ? props.indexColor : arrAllPayment.indexOf(props.modePay)
    let bgColor = squarePaymentColors[paymentIndex]

    let modePayName = t(props.modePay) ? t(props.modePay) : props.modePay

    const handleClick = () => {
        props.handleChange({target:{value:props.modePay}},props.handleChangeParam,false)
        if(props.amountVal=='') props.addPaymentDue() //props.isFor!='espece' && 
        if(props.isFor!='card' && props.isFor!='other' && props.amountVal!='' && props.amountVal!=props.valueDue) props.setRelanceAjoutPayment(Date.now())
    };
    
    return(
        <div className={`${(props.isFor=='espece' && props.indexColor==1) ? "col-12" : (props.isFor=='espece') ? "col-6" : "col-4"} position-relative`} style={{padding:"1px"}} >
            <div className="col-12 rounded raccourciPos text-white" style={{backgroundColor:bgColor}} onClick={()=>handleClick()}>
                <div className="textAlign p-2" style={{width:'100%', height:'100px'}}>
                    <div className="mb-1 retourChariotTexte">{props.isFor!='espece' ? modePayName : false}</div>
                    <div>{(+toPay>0) ? <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={+toPay} /> : (+toPay==0) ? '0'+devise : false}</div>
                </div>
            </div>
            {(props.btnDisabled || props.disabledPad || props.waitTpe) ?
                <div className="disabled rounded">
                </div>
            :
                false
            }
        </div>
    )
}

export default PadAddPayment;